import React, { FunctionComponent, ReactElement } from 'react';
import { SEO, SimpleHomeJumbotron, VendorAd } from '../components';
import { MainLayout } from '../layouts';

const HomePage: FunctionComponent = (): ReactElement => (
    <MainLayout>
        <SEO title="PCH | Home"/>
        <SimpleHomeJumbotron/>
        {/* <VendorAd/> */}
        <a href="/privacy-policy">{`Privacy Policy`}</a>
    </MainLayout>
);

export default HomePage;
